@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.normal-box-shadow {
  @apply shadow-[6px_6px_40px_8px_rgba(140,153,160,0.25)];
}

.hero-title {
  /* font-family: 'Josefin Sans', sans-serif; */
}

.title-header {
  @apply mb-4 font-bold uppercase;
}
.primary-btn {
  @apply border border-solid border-primary bg-primary p-4 px-12 text-white;
}

.primary-btn-outline {
  @apply border border-solid border-primary bg-white p-4 px-12 text-primary hover:bg-primary hover:text-white;
}

.rdw-editor-main {
  @apply h-[350px] overflow-scroll px-2 !important;
}

.dashboardDivider {
  overflow: hidden;
}

.select__control,
.select__menu {
  border-radius: 0px !important;
}

.select__menu-list {
  background-color: rgb(220, 220, 220);
}

@media screen and (max-width: 720px) {
  .select__menu-list {
    max-height: 150px !important;
  }
}

.dashboardDivider:after {
  content: '';
  height: 0.75em;
  vertical-align: bottom;
  width: 100%;
  margin-right: -100%;
  margin-left: 10px;
  border-top: 1px solid #bbb !important;
}

.ql-toolbar {
  position: sticky !important;
  top: 0;
  right: 0px;
  z-index: 50 !important;
  background: white;
}

.ql-container {
  padding-top: 15px;
  height: 550px !important;
}

@media screen and (max-width: 720px) {
  .ql-container {
    height: 500px !important;
  }
}

@media screen and (max-width: 660px) {
  .ql-container {
    height: 450px !important;
  }
}

.ReactGridGallery_tile {
  @apply w-full lg:w-auto;
}

.ReactGridGallery_tile-viewport {
  width: 100% !important;
}
.ReactGridGallery_tile-bottom-bar {
  z-index: 10;
}

.ril__navButtonNext {
  background: rgba(0, 0, 0, 1)
    url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjM0Ij48cGF0aCBkPSJtIDEsMyAyLC0yIDE2LDE2IC0xNiwxNiAtMSwtMSAxNSwtMTUgLTE1LC0xNSB6IiBmaWxsPSIjRkZGIi8+PC9zdmc+)
    no-repeat center;
}
.ril__navButtonPrev {
  background: rgba(0, 0, 0, 1)
    url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjM0Ij48cGF0aCBkPSJtIDE5LDMgLTIsLTIgLTE2LDE2IDE2LDE2IDEsLTEgLTE1LC0xNSAxNSwtMTUgeiIgZmlsbD0iI0ZGRiIvPjwvc3ZnPg==)
    no-repeat center;
}

.ril__closeButton {
  background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIj48cGF0aCBkPSJtIDEsMyAxLjI1LC0xLjI1IDcuNSw3LjUgNy41LC03LjUgMS4yNSwxLjI1IC03LjUsNy41IDcuNSw3LjUgLTEuMjUsMS4yNSAtNy41LC03LjUgLTcuNSw3LjUgLTEuMjUsLTEuMjUgNy41LC03LjUgLTcuNSwtNy41IHoiIGZpbGw9IiNGRkYiLz48L3N2Zz4=)
    no-repeat center;
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
